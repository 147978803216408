import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Inicio.vue"),
  },
  {
    path: "/sales",
    name: "sales",
    component: () => import("../views/Sales.vue"),
  },
  {
    path: "/new-product",
    name: "new-product",
    component: () => import("../views/NewProduct.vue"),
  },
  {
    path: "/new-product-g",
    name: "new-product-g",
    component: () => import("../views/NewProductG.vue"),
  },
  {
    path: "/barcode-scanner",
    name: "barcode-scanner",
    component: () => import("../views/BarcodeScanner.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/Producto.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () => import("../views/Productos.vue"),
  },
  {
    path: "/inventory",
    name: "inventory",
    component: () => import("../views/AddProducts.vue"),
  },
  {
    path: "/bussiness",
    name: "bussiness",
    component: () => import("../views/Negocio.vue"),
  },
  {
    path: "/owner",
    name: "owner",
    component: () => import("../views/Propietario.vue"),
  },
  {
    path: "/",
    name: "create-account",
    component: () => import("../views/CreateAccount.vue"),
  },
  {
    path: "/config-account",
    name: "config-account",
    component: () => import("../views/ConfAccount.vue"),
  },
  {
    path: "/configuration",
    name: "configuration",
    component: () => import("../views/Configuration.vue"),
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/ChangePassword.vue"),
  },
  {
    path: "/change-chain-data",
    name: "change-chain-data",
    component: () => import("../views/ChangeChainData.vue"),
  },
  {
    path: "/branches",
    name: "branches",
    component: () => import("../views/Branches.vue"),
  },
  {
    path: "/branches/create",
    name: "create-branch",
    component: () => import("../views/CreateBranch.vue"),
  },
  {
    path: "/branches/edit/:id",
    name: "edit-branch",
    component: () => import("../views/CreateBranch.vue"),
    props: true,
  },
  {
    path: "/branch-no-lot",
    name: "branch-no-lot",
    component: () => import("../views/BranchNoLot.vue"),
    props: true,
  },
  {
    path: "/employees",
    name: "employees",
    component: () => import("../views/Employees.vue"),
  },
  {
    path: "/employees/create",
    name: "create-employee",
    component: () => import("../views/CreateEmployee.vue"),
  },
  {
    path: "/employees/edit/:id/:index",
    name: "edit-employee",
    component: () => import("../views/CreateEmployee.vue"),
  },
  {
    path: "/config-account-employee",
    name: "config-account-employee",
    component: () => import("../views/ConfAccountEmployee.vue"),
  },
  {
    path: "/clients",
    name: "clients",
    component: () => import("../views/Clients.vue"),
  },
  {
    path: "/clients/create",
    name: "create-client",
    component: () => import("../views/CreateClient.vue"),
  },
  {
    path: "/clients/edit/:id",
    name: "edit-client",
    component: () => import("../views/CreateClient.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
