<template>
  <div>
    <!-- Si hay imágenes seleccionadas, muestra el carrusel -->
    <v-card v-if="files.length > 0" class="rounded-xl elevation-0">
      <v-carousel  max-height="250px">
        <v-carousel-item
          v-for="(file, index) in files"
          :key="index"
        >
          <v-img :src="file.preview" contain />
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <!-- Si no hay imágenes seleccionadas, muestra el card de subida -->
    <v-card
      v-else
      class="rounded-xl elevation-0 d-flex align-center justify-center text-center"
      :height="height"
      @click="triggerFileInput"
    >
      <v-card-text class="text-center py-auto">
        <v-icon class="mx-a" :size="iconSize">{{ icon }}</v-icon>
        <span class="text-body-1 tertiary--text">
          <br />
          {{ text }}
        </span>
      </v-card-text>
      <input
        ref="fileInput"
        type="file"
        multiple
        :accept="isImage ? 'image/*' : '*/*'"
        style="display: none"
        @change="handleFileUpload"
      />
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ProductUploadCard',
  props: {
    icon: {
      type: String,
      default: 'mdi-image-outline', // Icono predeterminado
    },
    text: {
      type: String,
      default: 'Da clic para subir la imagen del producto', // Texto predeterminado
    },
    height: {
      type: String,
      default: '170px', // Altura predeterminada del card
    },
    iconSize: {
      type: String,
      default: '40px', // Tamaño predeterminado del icono
    },
    isImage: {
      type: Boolean,
      default: true, // Define si solo se deben permitir imágenes
    },
  },
  data() {
    return {
      files: [], // Aquí se almacenarán las imágenes seleccionadas
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click(); // Dispara el clic en el input oculto para seleccionar archivos
    },
    handleFileUpload(event) {
      const selectedFiles = event.target.files;
      if (selectedFiles.length > 0) {
        // Limpiar archivos previos
        this.files = [];

        // Procesar cada archivo seleccionado
        Array.from(selectedFiles).forEach((file) => {
          if (this.isImage && !file.type.startsWith('image/')) {
            return; // Ignorar archivos que no son imágenes si isImage es true
          }

          const reader = new FileReader();
          reader.onload = (e) => {
            this.files.push({
              file,
              preview: e.target.result, // Generar vista previa de la imagen
            });
          };
          reader.readAsDataURL(file);
        });

        this.$emit('files-uploaded', this.files); // Emitir el evento con los archivos cargados
      }
    },
  },
};
</script>

<style scoped>
/* Estilos personalizados */
</style>
