<template>
  <div>
    <v-snackbar
      v-model="show"
      :timeout="timeout"
      :color="color"
      bottom
      app
    >
      {{ message }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'ToastNotification',
  data() {
    return {
      show: false,
      message: '',
      color: 'primary',
      timeout: 3000,
    };
  },
  methods: {
    showToast({ message, color, timeout = 3000 }) {
      this.message = message;
      this.color = color;
      this.timeout = timeout;
      this.show = true;
    },
  },
};
</script>

<style scoped>

</style>
