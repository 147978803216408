<template>
  <div class="mt-n2">
    <span class="textlabel--text">{{ label }}</span>
    <v-select
      class="white rounded-xl"
      hide-details="auto"
      outlined
      dense
      clearable
      :items="items"
      :value="value"
      :prepend-icon="prependIcon"
      :rules="rules"
      :required="required"
      :prefix="prefix"
      :placeholder="placeholder"
      :disabled="disabled"
      :item-text="itemText"
      :item-value="itemValue"
      @input="$emit('input', $event)"
    >
      <slot name="append" slot="append"></slot>
      <slot name="prepend" slor="prepend"></slot>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "PatSelect",
  props: {
    items: {
      type: Array,
      required: true,
    },
    prefix: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false, // No obligatorio
      default: null, // Valor predeterminado
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemText: {
      type: String,
      required: false,
      default: "text",
    },
    itemValue: {
      type: String,
      required: false,
      default: "value",
    },
  },
};
</script>

<style scoped>
span {
  font-size: 14px;
  font-weight: 500;
}
</style>
