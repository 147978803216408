<template>
  <v-app>
    <v-container class="px-10">
      <v-row>
        <v-col cols="12" class="d-flex justify-center mb-5 mt-15">
          <v-img
            :src="require('@/assets/pat-pos-logo.png')"
            alt="PAT POS Logo"
            class="mx-auto"
            contain
            max-width="146px"
          />
        </v-col>

        <v-card
          class="rounded-xl pa-2"
          style="background-color: #f4f4fa"
          min-height="380px"
        >
          <!-- Título centrado -->
          <v-card-title
            class="d-flex justify-center align-center text-h5 font-weight-bold"
          >
            {{ rol === 1 ? "Bienvenido a PAT" : "Valida tu cuenta" }}
          </v-card-title>

          <v-card-text class="text-center">
            <p v-if="rol === 1">
              Para comenzar a configurar tu cuenta, ingresa el código que
              recibiste por SMS al número <strong>{{ phoneNumber }}</strong
              >.
            </p>
            <p v-if="rol === 2">
              Ingresa el código que tu empleador compartió contigo
            </p>

            <!-- Inputs del código de verificación -->
            <div class="my-4">
              <p class="text-start mb-0">Código</p>
              <div class="d-flex justify-center">
                <v-otp-input
                  autofocus
                  color="light-green"
                  :length="6"
                  type="number"
                  placeholder="0"
                  v-model="otp"
                  :disabled="loading"
                  @finish="onFinish"
                  class="custom-otp-input mb-12"
                ></v-otp-input>
                <v-overlay absolute :value="loading">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </div>
            </div>

            <!-- Mensaje de error si el código no está completo -->
            <p v-if="codeError" class="red--text font-weight-bold">
              Por favor, ingrese el código completo.
            </p>

            <p
              v-if="rol === 1"
              class="blue--text text-decoration-underline font-weight-bold"
            >
              Volver a enviar código
            </p>
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              class="py-6"
              @click="handleContinue"
              :color="isCodeComplete ? 'primary' : 'grey darken-1'"
              dark
              block
              rounded
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { verifyOtp } from "../services/auth";
export default {
  name: "verification-code",
  data() {
    return {
      loading: false,
      otp: "",
      verificationCode: Array(6).fill(""),
      codeError: false,
    };
  },
  props: {
    phoneNumber: {
      type: String,
      required: false,
    },
    rol: {
      type: Number,
      required: true,
    },
    pathName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCodeComplete() {
      return this.verificationCode.every((code) => code !== "");
    },
  },
  methods: {
    handleContinue() {
      const isCodeComplete = this.verificationCode.every((code) => code !== "");
      if (isCodeComplete) {
        this.redirectTo(this.pathName);
      } else {
        this.codeError = true;
      }
    },
    redirectTo(name) {
      this.$router.push({ name: name });
    },
    moveToNext(index) {
      if (this.verificationCode[index] && index < 5) {
        this.$nextTick(() => {
          this.$refs[`input-${index + 1}`][0].focus();
        });
      }
    },
    async onFinish(value) {
      this.loading = true;
      const verificado = await verifyOtp(this.phoneNumber, value);
      this.loading = false;
      if (verificado) {
        this.redirectTo(this.pathName);
      } else {
        this.$toast({
          message: "Código incorrecto. Por favor, inténtalo de nuevo.",
          color: "error",
          timeout: 2000,
        });
      }
    },
  },
};
</script>

<style>
/* Contenedor principal */
.custom-otp-input {
  display: flex;
  flex-wrap: nowrap;
}

.custom-otp-input .v-input {
  width: 0.45rem !important;
}
/* Ajustes del control interno */
.custom-otp-input .v-input__control {
  border-radius: 15px !important;
}
/* Input interno */
.custom-otp-input .v-text-field__slot input {
  text-align: center;
  font-size: 18px !important;
}
</style>
