import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5E90F4",
        secondary: "#031A83",
        tertiary: "#73788C",
        accent: "#8c9eff",
        error: "#b71c1c",
        textgrey: "#73788C",
        textlabel: "#454854",
        bgcards: "#F6F5FA",
      },
    },
  },
});
