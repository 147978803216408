import { defineStore } from "pinia";
import {getUserDetails} from "../services/auth";

export const useUserStore = defineStore("user", {
  state: () => ({
    user: null, // Datos del usuario
    token: localStorage.getItem("token") || null, // Obtener token del localStorage
  }),
  actions: {
    async getUser() {
      const response = await getUserDetails();
      if (response.success) {
        this.setUser(response.user);
      }
    },
    setUser(userData) {
      this.user = userData;
    },
    logout() {
      this.user = null;
      this.token = null;
      localStorage.removeItem("token"); // Elimina el token al cerrar sesión
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.user.nombre, // Comprueba si el usuario está autenticado
    userName: (state) => state.user?.nombre || "Invitado",
  },
});
