<template>
  <div class="mt-n2">
    <span class="textlabel--text">{{ label }}</span>
    <v-text-field
      v-if="!textarea"
      class="white rounded-xl"
      hide-details="auto"
      outlined
      dense
      persistent-placeholder
      :type="passVisible ? 'text' : type"
      v-model="inputValue"
      :prepend-icon="prependIcon"
      :append-icon="appendIcon"
      :rules="rules"
      :required="required"
      :prefix="prefix"
      :placeholder="placeholder"
      :messages="messages"
      :disabled="disabled"
      :maxlength="maxlength"
      @input="onInput"
      @keydown="onKeydown"
    >
      <slot name="append" slot="append">
        <v-btn
          icon
          v-if="type === 'password'"
          class="mt-n1"
          @click="passVisible = !passVisible"
        >
          <v-icon>{{
            passVisible ? "mdi-eye-off-outline" : "mdi-eye-outline"
          }}</v-icon>
        </v-btn>
      </slot>
    </v-text-field>
    <v-textarea
      v-else
      :placeholder="placeholder"
      persistent-placeholder
      clearable
      class="white rounded-xl"
      hide-details="auto"
      outlined
      dense
      height="120px"
      no-resize
      v-model="inputValue"
      @input="$emit('input', inputValue)"
    />
  </div>
</template>

<script>
export default {
  name: "PatInput",
  data() {
    return {
      passVisible: false,
      inputValue: this.value, // Inicializa inputValue con el valor pasado como prop
    };
  },
  props: {
    prefix: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    appendIcon: {
      type: String,
      required: false,
    },
    messages: {
      type: Array,
      required: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    textarea: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: null,
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    // Sincroniza el valor de inputValue con la prop `value` cuando cambia
    value(newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    // Filtrar caracteres no numéricos y limitar longitud
    onInput() {
      if (this.isNumber) {
        const filteredValue = this.inputValue
          .replace(/[^0-9]/g, "")
          .slice(0, this.maxlength || 10);
        this.$emit("input", filteredValue); // Emite el valor filtrado
      } else {
        this.$emit("input", this.inputValue); // Emite el valor sin cambios
      }
    },

    // Bloquear teclas no deseadas
    onKeydown(event) {
      if (
        this.isNumber &&
        !/[0-9]/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault(); // Prevenir teclas no numéricas
      }
    },
  },
};
</script>

<style scoped>
span {
  font-size: 14px;
  font-weight: 500;
}
</style>
