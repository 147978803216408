import axios from "axios";
import router from "@/router";

const api = axios.create({
  baseURL: "https://www.argoth.com.mx/api",
  // baseURL: "http://192.168.0.7:3000/api",
});
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("usuarioDetalle");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

const login = async (telefono, password) => {
  try {
    const response = await api.post("/auth/login", {
      telefono,
      contraseña: password,
    });
    const { token, usuarioDetalle } = response.data;
    localStorage.setItem("token", token);
    localStorage.setItem("usuarioDetalle", JSON.stringify(usuarioDetalle));
    return 1;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      const { code } = error.response.data;
      console.log("Error logging in:", code);
      if (code === "OTP_NOT_VERIFIED") {
        return 2;
      }
      if (code === "OTP_NOT_VERIFIED_EMPLOYEE") {
        return 3;
      }
      return 0;
    }
    console.error("Error logging in:", error);
    return false;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("usuarioDetalle");
  return true;
};

const verifyOtp = async (telefono, otp) => {
  try {
    const response = await api.post("/auth/verify-otp", {
      telefono,
      otp,
    });
    const { token } = response.data;
    localStorage.setItem("token", token);
    return true;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    return false;
  }
};

const register = async (telefono, contraseña) => {
  try {
    const response = await api.post("/auth/register", {
      telefono,
      contraseña,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error("Error registering user:", error.response.data.mensaje);
      return { success: false, message: error.response.data.mensaje };
    }
    console.error("Error registering user:", error);
    return { success: false, message: "Error al registrar usuario" };
  }
};

const saveUserDetails = async (
  nombre,
  fecha_nacimiento,
  correo_electronico
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/usuario-detalle",
      { nombre, fecha_nacimiento, correo_electronico },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving user details:", error);
    return {
      success: false,
      message: "Error al guardar los detalles del usuario",
    };
  }
};

const saveBusinessDetails = async (giro_id, nombre, direccion) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/negocios",
      { giro_id, nombre, direccion },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving business details:", error);
    return {
      success: false,
      message: "Error al guardar los detalles del negocio",
    };
  }
};

const getUserDetails = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/usuario-detalle", {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting user details:", error);
    return {
      success: false,
      message: "Error al obtener los detalles del usuario",
    };
  }
};

const changePassword = async (oldPassword, newPassword) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/auth/change-password",
      { oldPassword, newPassword },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    return { success: false, message: "Error al cambiar la contraseña" };
  }
};

const createSucursal = async (nombre, calle, telefono, tipo_sucursal) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      "/sucursales/",
      { nombre, calle, telefono, tipo_sucursal },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating sucursal:", error);
    return { success: false, message: "Error al crear la sucursal" };
  }
};

const getSucursales = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/sucursales/", {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting sucursales:", error);
    return { success: false, message: "Error al obtener las sucursales" };
  }
};

const getSucursal = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/sucursales/" + id, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting sucursales:", error);
    return { success: false, message: "Error al obtener la sucursal " + id };
  }
};

const updateSucursal = async (id, nombre, calle, telefono, tipo_sucursal) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      `/sucursales/${id}`,
      { nombre, calle, telefono, tipo_sucursal },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating sucursal:", error);
    return { success: false, message: "Error al actualizar la sucursal" };
  }
};

const deleteSucursal = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/sucursales/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting sucursal:", error);
    return { success: false, message: "Error al eliminar la sucursal" };
  }
};

const createClient = async (
  fullName,
  contactPhone,
  email,
  applicablePrice,
  comments,
  address,
  invoice,
  requiresInvoices
) => {
  try {
    const token = localStorage.getItem("token");

    const response = await api.post(
      "/clientes/",
      {
        fullName,
        contactPhone,
        email,
        applicablePrice,
        comments,
        address,
        invoice,
        requiresInvoices,
      },
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating client:", error);
    return { success: false, message: "Error al crear el cliente" };
  }
};

const updateClient = async (
  id,
  fullName,
  contactPhone,
  email,
  applicablePrice,
  comments,
  address,
  invoice,
  requiresInvoices
) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(
      `/clientes/update/${id}`,
      {
        fullName,
        contactPhone,
        email,
        applicablePrice,
        comments,
        address,
        invoice,
        requiresInvoices,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating client:", error);
    return { success: false, message: "Error al actualizar el cliente" };
  }
};

const getClients = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/clientes/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting clients:", error);
    return { success: false, message: "Error al obtener los clientes" };
  }
};

const getClient = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/cliente/" + id, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting client:", error);
    return { success: false, message: "Error al obtener el cliente " + id };
  }
};

const deleteClient = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.delete(`/clientes/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting client:", error);
    return { success: false, message: "Error al eliminar el cliente" };
  }
};

const getEmpleado = async (id) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get(`/auth/employees/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting employee:", error);
    return { success: false, message: "Error al obtener al empleado" };
  }
};

const createEmpleado = async (empleado) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(`/auth/employees/`,empleado, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error setting employee:", error);
    return { success: false, message: "Error al crear al empleado" };
  }
};

const updateEmpleado = async (id,empleado) => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.post(`/auth/employees/${id}`,empleado, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating employee:", error);
    return { success: false, message: "Error al actualizar al empleado", error: error.response.data.error };
  }
};
const getEmpleados = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await api.get("/auth/employees", {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting employees:", error);
    return { success: false, message: "Error al obtener los empleados" };
  }
};

export {
  login,
  logout,
  verifyOtp,
  register,
  saveUserDetails,
  saveBusinessDetails,
  getUserDetails,
  changePassword,
  createSucursal,
  getSucursales,
  updateSucursal,
  deleteSucursal,
  getSucursal,
  createClient,
  updateClient,
  getClients,
  getClient,
  deleteClient,
  getEmpleados,
  getEmpleado,
  createEmpleado,
  updateEmpleado,
};
